(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name category.factory:Category
   *
   * @description
   *
   */
  angular
    .module('category')
    .factory('Category', Category);

  function Category(consts, $http) {
    var CategoryBase = {};



    return CategoryBase;
  }
}());
